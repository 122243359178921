//Dialog
.mat-dialog-container {
	padding: 16px !important;
	max-width: 500px;
	min-width: 260px;
	width: 85vw !important;
	@media (min-width: $mobile-min) {
		// min-width: 350px;
		min-width: unset;
		width: unset !important;
	}
	@media (max-width: $mobile-min) {
		min-width: unset;
		width: unset !important;
	}
	.mat-divider {
		width: calc(100% + 32px);
		margin-left: -16px;
		margin-bottom: 16px;
	}
	.form-field {
		display: flex;
		flex-wrap: wrap;
		.mat-form-field {
			width: 100%;
			&.left,
			&.right {
				width: 50%;
			}
			&.mobile-full {
				@media (max-width: $mobile-max) {
					width: 100%;
					padding: 0px;
				}
			}
		}
	}
}

.mat-dialog-content {
	// margin: 0 -16px !important;
	// padding: 0 16px !important;
}
.mat-dialog-title {
	font-weight: 700;
	font-size: 16px;
	margin-bottom: 8px !important;
}

.mat-dialog-actions {
	float: right;
	margin-bottom: -16px !important;
	.mat-button {
		font-weight: 600;
	}
	&.with-divider {
		margin-top: -16px !important;
	}
}

//Dialog

.faq-dialog {
	.mat-dialog-content {
		margin: -16px !important;
		padding-bottom: 32px;
	}
	.mat-dialog-container {
		max-width: 1000px;
	}
	.faq-title {
		font-size: 14px;
		font-weight: 700;
		margin-bottom: 8px;
	}

	.faq-desc {
		margin-bottom: 32px;
		font-size: 14px;
		line-height: 150%;
		padding-left: 12px;
	}
	ol {
		padding-left: 24px;
	}
}

.guide-dialog {
	.mat-dialog-container {
		max-width: 1000px;
		padding: 0px !important;
	}
	.logo {
		width: 100%;
		padding: 16px;

		img {
			width: 20%;
		}
		@media only screen and (max-width: 960px) {
			display: none;
		}
	}
	.desktop-graphic {
		@media only screen and (max-width: 960px) {
			display: none;
		}
	}
	.mobile-graphic {
		display: block;
		width: 100%;
		@media only screen and (min-width: 961px) {
			display: none;
		}
	}
	.guide-step-content {
		@media only screen and (max-width: 960px) {
			padding: 16px;
		}
		.guide-title {
			font-size: 20px;
			text-transform: uppercase;
			font-weight: bold;
			line-height: 150%;
			@media only screen and (max-width: 960px) {
				font-size: 16px;
			}
		}
		.guide-highlight {
			font-size: 20px;
			font-weight: bold;
			color: white;
			width: fit-content;
			background-color: $primary-color;
			border-radius: 10px;
			padding: 4px 10px;
			margin-bottom: 26px;
		}

		.guide-step-graphic {
			width: 100%;
			display: block;
		}

		.guide-step-wrapper {
			display: flex;
			align-items: center;
			margin-bottom: 20px;
		}
		.step-title {
			font-size: 14px;
			font-weight: 700;
			line-height: 150%;
			margin-bottom: 6px;
		}
		.step-desc {
			font-size: 12px;
			font-weight: 500;
			line-height: 150%;
		}
		.step-icon {
			width: 60px;
			margin-right: 16px;
			@media only screen and (max-width: 960px) {
				width: 52px;
			}
		}
	}

	.guide-highlight-content {
		background-color: $primary-color;
		color: white;
		padding: 16px;
		@media only screen and (max-width: 960px) {
			display: none;
		}
		.highlight-icon-wrapper {
			display: flex;
			align-items: center;
			font-weight: bold;
			&:first-child {
				margin-right: 40px;
				div {
					width: 170px;
				}
			}
			img {
				margin-right: 16px;
			}
		}
		.desc {
			margin-top: 16px;
			font-size: 12px;
			text-align: right;
		}
	}
}

.submit-success-dialog {
	.mat-dialog-container {
		padding: 0px !important;
	}
}

.no-padding-border-radius-dialog {
	.mat-dialog-container {
		padding: 0px !important;
        border-radius: 10px;
	}
}

.ktmb-dialog {
  .mat-dialog-container {
    padding: 0 !important;
    max-width: 600px !important;
    width: 100% !important;
  }
}
