$mobile-max: 768px;
$tablet-max: 1020px;
$desktop-min: 1021px;
$mobile-min: 769px;

@use "@angular/material" as mat;
@import "assets/styles/colors/default-colors.scss";

@import "@angular/material/theming";
@import "~swiper/css";
@import "~swiper/css/pagination";
@import "~swiper/css/navigation";
@import "assets/styles/layouts";
@import "assets/styles/space";
@import "assets/styles/font";
@import "assets/styles/dialog";
@import "assets/styles/colors";
@include mat.core();
@import "assets/styles/form";

/* You can add global styles to this file, and also import other style files */

$brand: (
  100: #7092bb,
  200: #6d94ff,
  300: #7fa3d1,
  400: #6992c9,
  500: #5282c1,
  600: #4072b4,
  700: #38649d,
  contrast: (
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
  ),
);

$primary: mat-palette($brand, 100);
$accent: mat.define-palette(mat.$blue-palette, 700);
$warn: mat.define-palette(mat.$red-palette, 800);

$typography: mat.define-typography-config(
  $font-family: $font-family,
);

$theme: mat.define-light-theme(
  (
    color: (
      primary: $primary,
      accent: $accent,
    ),
    typography: $typography,
  )
);

.mat-checkbox-layout {
  white-space: normal !important;
}

@include mat.all-component-themes($theme);

$accent-color: mat.get-color-from-palette($accent, default);
$warn-color: mat.get-color-from-palette($accent, default);
$muted-color: #99abb4;

html,
body {
  height: 100%;
}

* {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: $font-family;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: $font-family !important;
}

.wrapper {
  min-height: 100vh;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
}

.mat-body,
.mat-body-1,
.mat-typography {
  font-family: $font-family;
}

.mat-simple-snackbar-action button {
  padding: 0px 16px !important;
}

//Colors
.color-grey {
  color: #505050;
}
.color-orange {
  color: #d19900;
}
.color-red {
  color: #ff0022;
}
.color-green {
  color: #7ed402;
}
.color-dark-green {
  color: #24a148;
}
.color-teal {
  color: #0096b5;
}
.color-blue {
  color: $primary-color;
}
.color-dark-blue {
  color: #0245a3;
}
.color-primary {
  color: #0091ff;
}

.color-light-grey {
  color: #94a3b8 !important;
}

.color-white {
  color: white;
}

.color-black {
  color: #000 !important;
}

.color-yellow {
  color: #ffd101;
}

.color-secondary {
  color: $secondary-color;
}

.color-info {
  color: #7092bb;
}

.secondary-color {
  color: $secondary-color !important;
}

// icon colors
.icon-filter {
  filter: $icon-filter !important;
}

.icon-filter-cheapest {
  filter: $icon-filter-cheapest !important;
}

// BUTTONS
.mat-flat-button {
  border-radius: 7px;
  font-size: 14px;
  padding: 14px 16px !important;
  min-height: fit-content !important;
  line-height: 18px !important;
  font-weight: 600;
  &.mat-primary {
    background: $secondary-color !important;
    color: $secondary-text-color !important;
  }
  &.mat-secondary {
    background: $primary-color;
    color: $primary-text-color;
  }
  &.muted {
    background: $muted-color;
    color: #fff;
  }
  &.full-width {
    width: 100%;
  }
}

.mat-button {
  font-size: 14px;
  padding: 16px !important;
  min-height: fit-content !important;
  line-height: 18px !important;
  font-weight: 700;
  &.secondary {
    background: #e0ecff;
  }
}

.full-btn {
  background-color: $secondary-color;
  width: 100%;
  color: $secondary-text-color !important;
  line-height: 150%;
  font-size: 14px;
  &.third {
    color: #000;
  }
  &.white {
    background-color: #fff;
    color: #000;
  }
  &.mat-button-disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
  }
}

.stroked-btn {
  border: 1px solid $primary-color !important;
  color: $primary-color;
  width: 100%;
  line-height: 150%;
  font-size: 14px;
  height: 46px;
}

.b2c-radio {
  margin-top: 4px !important;
  display: flex !important;

  &.ehailing-radio {
    margin-top: 12px !important;
  }

  .mat-radio-checked {
    .mat-radio-outer-circle {
      border-color: $secondary-color !important;
    }
    .mat-radio-inner-circle {
      background-color: $secondary-color !important;
    }
    .mat-radio-label-content {
      color: #122545;
      font-weight: 600;
    }
  }

  .mat-radio-button {
    color: #7092bb;
    flex: 1;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 0px !important;
    width: 100%;
    border-radius: 10px;
    &.mat-radio-checked {
      color: $active-text-color;
      font-weight: 700;
    }
    &:first-child {
      margin-right: 16px;
    }
    &.mat-radio-disabled {
      .mat-radio-inner-circle {
        background-color: #7092bb !important;
      }
      .mat-radio-outer-circle {
        border-color: #7092bb !important;
      }
    }
  }
  .mat-radio-label-content {
    text-align: center;
    // color: white !important;
    padding-left: 0px;
    opacity: 1 !important;
  }
  .mat-radio-container {
    margin-right: 8px;
    .mat-radio-outer-circle {
      border-color: #7092bb;
    }
  }
}

.secondary-btn {
  width: 100%;
  background: $primary-color;
  color: $primary-text-color;
  font-weight: 800;
}

.white-btn {
  width: 100%;
  border: 1px solid $secondary-color !important;
  font-weight: 800;
  color: $secondary-color;
}

// plans, comparison
.step-back-btn {
  font-size: 16px;
  font-weight: 500;
  margin: 25px 10px 5px !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;

  .mat-icon {
    border-radius: 100px;
    border: 1px solid black;
    font-size: 18px;
    line-height: 22px;
    margin-right: 8px;
  }
}
// END BUTTONS

// MARGINS
.m-t-0 {
  margin-top: 0px;
}
// END MARGINS

// Dialog Classes
.success-dialog {
  .mat-dialog-container {
    border-radius: 15px;
    padding: 0px !important;
  }
}

.success-content {
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;

  padding: 24px;
  button {
    margin-top: 24px;
    width: 100%;
    background: $secondary-color;
    color: $secondary-text-color;
  }
}

.success-graphic {
  background-color: #ebf6ff;
  font-size: 24px;
  font-weight: 700;
  line-height: 150%;
  color: $primary-color;
  display: flex;
  align-items: center;
  padding: 24px;
  img {
    margin-right: 16px;
  }
}

.fail-dialog {
  .mat-dialog-container {
    border-radius: 8px;
    padding: 0px !important;
    width: 400px !important;
    min-width: 310px !important;
  }
}

.fail-content {
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  line-height: 150%;
  padding: 16px;
  &__btn {
    min-width: 128px !important;
    height: 38px;
    font-size: 12px;
    font-weight: 600;
    color: $primary-color;
    border-color: $primary-color !important;
    border-radius: 7px !important;
    &:hover {
      background-color: $color-light-blue;
    }
  }
}

.fail-graphic {
  background-color: $color-light-blue;
  font-size: 20px;
  font-weight: 700;
  line-height: 150%;
  color: $color-accent-red;
  display: flex;
  align-items: center;
  padding: 16px;
  gap: 8px;
}

.renewal-graphic {
  img {
    display: block;
    margin: 0 auto 20px;
    width: 120px;
  }
}

.renewal-content {
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
  padding: 16px;
  .mat-icon {
    margin-right: 16px;
    color: #ff0022;
  }
  b {
    font-size: 16px;
    font-weight: 800;
  }
}

.menu-dialog {
  .mat-dialog-container {
    background: none;
    box-shadow: none;
    color: white;
    text-align: center;
  }
  a {
    font-size: 24px;
    font-weight: 800;
    margin-bottom: 60px;
    text-decoration: none;
    display: block;
    color: white;
  }
  .dismiss-btn {
    position: absolute;
    right: 16px;
    top: 16px;
    background: white;
    color: black;
  }
}

.menu-blackdrop {
  background: rgb(0 0 0 / 84%);
}

.benefit-dialog {
  .mat-dialog-container {
    padding: 0px !important;
  }
  .benefit-content {
    padding: 24px;
    max-height: 50vh;
    overflow-y: auto;
  }
  .title {
    color: $primary-color;
    font-size: 16px;
    line-height: 150%;
    font-weight: 700;
    margin-bottom: 16px;
  }
  .expand-content {
    align-items: center;
  }
  .links {
    padding: 0px 24px 24px;
    a {
      margin-bottom: 10px;
      display: block;
      color: $primary-color;
      font-weight: 500;
    }
  }
}
// END Dialog Classes

// SHARED STYLES
// customer-policy.component, menu.component
.sidefix-menu {
  position: fixed;
  top: 130px;
  right: 32px;
  display: flex;
  flex-direction: column;
  margin: auto;
  text-align: right;
  align-items: flex-end;
  z-index: 10;
  @media only screen and (max-width: 960px) {
    display: none;
  }

  img {
    margin-right: 4px;
  }

  .mat-raised-button {
    text-align: left;
    border: 2px solid $primary-color;
    border-radius: 100px;
    margin-bottom: 12px;
    padding: 8px 16px !important;
    width: 120px;
    img {
      filter: $img-mask-primary;
    }
  }
}

.addon-card {
  box-shadow: none !important;
  border: 1px solid #73859c;
  margin-bottom: 16px;
  &.selected {
    border: 2px solid $secondary-color;
    .value {
      color: black;
    }
    .mat-checkbox {
      margin-bottom: 16px;
    }
  }
  &.included {
    border: 2px solid $primary-color;
    background-color: #d8eeff;
    .included-banner {
      width: fit-content;
      position: absolute;
      left: -12px;
      padding: 4px 12px;
      top: -10px;
      background-color: $primary-color;
      color: #fff;
      font-weight: 700;
      display: flex;
      align-items: center;
      border-radius: 100px;
      text-transform: uppercase;
      mat-icon {
        margin-left: 8px;
        width: 20px;
        height: 20px;
        font-size: 20px;
        line-height: 20px;
        transform: rotate(0.03deg);
      }
    }
    .title {
      margin-top: 12px;
    }
  }
  &.borderless {
    border: none !important;
  }
  &.cover-note {
    margin-right: 35px;
  }
  .tooltip-icon {
    color: #7092bb;
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    text-align: right;
    font-size: 24px;
    margin-left: 16px;
  }
  .mat-checkbox-inner-container {
    margin: 2px 10px auto 0;
    height: 24px;
    width: 24px;
    top: -2px;
  }
  .mat-checkbox.mat-warn {
    .mat-checkbox-inner-container {
      height: 16px;
      width: 16px;
      margin: 0px 12px 0px 3px;
      top: 2px;
      .mat-checkbox-frame {
        border-color: #ff0000;
      }
    }
    .mat-checkbox-label {
      color: #ff0000;
      font-weight: 500;
      line-height: 20px;
      white-space: pre-wrap;
    }
  }
  .mat-checkbox,
  .mat-checkbox-layout,
  .mat-checkbox-label {
    width: 100%;
  }

  .mat-form-field-appearance-legacy {
    .mat-form-field-label {
      transform: translateY(-1.28125em) scale(1) perspective(100px)
        translateZ(0.00106px) !important;
      mat-label {
        color: #000000 !important ;
      }
    }
    input {
      line-height: 32px;
    }
    .mat-form-field-underline,
    .mat-form-field-ripple {
      background-color: #979797 !important;
    }
  }
  .title {
    line-height: 150%;
    font-size: 14px;
    font-weight: 700;
    white-space: normal;
  }
  .desc {
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
  }
  .sub-right {
    text-align: right;
    padding-right: 36px;
    padding-left: 8px;
  }
  .value {
    line-height: 140%;
    font-size: 18px;
    font-weight: 700;
    color: #7092bb;
    span {
      font-size: 12px;
      font-weight: 400;
      position: relative;
      top: -4px;
    }
  }
  .sub-value {
    font-size: 12px;
    font-weight: 500;
    line-height: 150%;
  }
  .slider-desc {
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
  }
  .slider-value {
    width: 85%;
    font-weight: 700;
    @media (max-width: 600px) {
      width: 80%;
    }
  }
  .mat-slider {
    width: 85%;
    margin: 10px auto -6px;
    @media (max-width: 600px) {
      width: 80%;
    }
  }
  .mat-slider-track-fill {
    background: #ffd100 !important;
  }
  .mat-slider-thumb {
    background: white !important;
    border: 4px solid #ffd100;
    transform: scale(0.7) !important;
  }
  .mat-slider-thumb-label {
    background-color: unset !important;
    width: fit-content;
    margin-right: -12px;
    margin-top: 8px;
    transform: rotate(45deg) !important;
  }
  .mat-slider-thumb-label-text {
    color: black !important;
    font-weight: bold;
    font-size: 14px;
    opacity: 1 !important;
  }
  .mat-slider-track-background,
  .mat-slider-track-fill {
    height: 3px !important;
  }
  .mat-radio-group {
    display: block;
    margin-top: 16px;
    color: #7092bb;

    .mat-radio-label {
      // align-items: middle;
    }
    .mat-radio-container {
      top: 2px;
    }

    .mat-radio-label-content {
      width: 100%;
      opacity: 0.3;
    }
    .mat-radio-checked {
      .mat-radio-label-content {
        opacity: 1;
        color: black;
      }
      .value {
        color: black;
      }
    }
  }
  .driver-count {
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
    color: $primary-color;
  }
  .add-driver-btn {
    border: 1px solid $secondary-color;
    font-weight: 700;
    line-height: 150%;
    padding: 10px 16px;
    .mat-icon {
      color: $primary-color;
      margin-right: 8px;
    }
  }
  .driver-delete {
    border: 1px solid $primary-color;
    height: 34px !important;
    border: 1px solid $primary-color;
    width: 34px;
    padding: 0px !important;
    line-height: 20px;
  }
  .driver-divider {
    width: 100%;
    height: 1px;
    padding: 0px 16px;
    background-color: $primary-color;
    margin: 0px 16px;
  }

  .roadtax-content__notice {
    font-size: 13px !important;
    font-weight: 600 !important;
    background-color: #fff4f4;
    border: 1px solid #e3e3e3;
    padding: 4px 32px;
    width: 90%;
    margin-top: 16px;
    margin-left: 24px;
    &__title {
      color: #fa4c4c;
    }

    &__desc {
    }
    @media (max-width: 450px) {
      margin-left: 5px;
    }
  }
}

// landing, customer-details, dashboard
.upperc-input {
  input {
    text-transform: uppercase;
  }
}

// comparison, customer-policy, topup-request
.section-container {
  margin-bottom: 16px;
  .title-sec {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 18px;

    .desc {
      font-size: 14px;
      font-weight: 400;
      line-height: 150%;
      width: 100%;
    }

    .divider {
      width: 100%;
      height: 1px;
      padding: 0px 16px;
      margin-left: 16px;
      background-color: #979797;
      flex: 1;
    }
  }
  .content-wrapper {
    margin-top: 0px;
  }
}

// comparison, topup-request
.summary-divider {
  width: 100%;
  height: 1px;
  padding: 0px 16px;
  background-color: $primary-color;
  margin-left: 16px;
  &.grey {
    background-color: #979797;
  }
}

// comparison, topup-request
.payment-options {
  background-color: #fff8d7;
  margin: 0px -24px;
  padding: 16px;
  .mat-radio-group {
    margin: 10px -6px 0px;
  }
  .mat-radio-container {
    display: none;
  }
  .mat-radio-button {
    margin-bottom: 0px;
    border: 1px solid #7092bb;
    background-color: white;
    text-align: center;
    border-radius: 5px;
    margin: 0px 6px;
    color: #7092bb;
    padding: 16px 0px;
    align-items: center;
    display: flex;
  }
  .mat-radio-label-content {
    padding-left: 0px;
  }
  .mat-radio-label {
    justify-content: center;
  }
  .mat-radio-checked {
    background: linear-gradient(180deg, #ffea00 0%, #ffd100 100%);
    border: 1px solid #fff8d7;
    color: black;
  }

  .desc {
    font-size: 12px;
    font-weight: 700;
    line-height: 150%;
  }
  .value {
    font-size: 22px;
    font-weight: 700;
    line-height: 150%;
  }
  .sub-desc {
    font-size: 12px;
    font-weight: 400;
    line-height: 100%;
  }
}

// mobile-bottombar, comparison, topup
.expand-blackdrop {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  background: rgba(0, 0, 0, 0.32);
  @media only screen and (min-width: 961px) {
    display: none;
  }
}

// plans, mobile-bottombar, summary-card, comparison, topup-request
.summary-card {
  // padding: 24px 24px 0px !important;
  // position: sticky !important;
  // top: 10px;
  padding: 22px 12px 28px !important;
  width: calc(100% - 31px);
  box-shadow: 0 3px 6px 1px #c1c5e46b !important;
  @media only screen and (max-width: 960px) {
    overflow: unset !important;
    position: fixed !important;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0px !important;
    box-shadow: 0px 0px 15px 0px #00000040 !important;
    z-index: 1000;
  }
  &.mat-expansion-panel {
    display: flex !important;
    flex-direction: column;
  }

  .mat-expansion-panel-body {
    padding: 0px;
    @media only screen and (max-width: 960px) {
      padding: 16px 16px 0px;
    }
    .expanded-content {
      @media only screen and (max-width: 960px) {
        max-height: 70vh;
        overflow: auto;
      }
    }

    .content-wrapper,
    .payment-option {
      padding: 0px 20px !important;
    }

    .pricing {
      .content-wrapper {
        padding: 0px !important;
      }
    }

    .total-amt {
      color: #24a148;
      margin-top: 20px;
      .left .label-desc,
      .right {
        font-weight: 700 !important;
      }

      mat-divider {
        width: 100%;
        padding-bottom: 24px;
      }
    }
  }

  .mat-expansion-panel-header {
    padding: 30px 16px 16px !important;
    order: 2;
    @media only screen and (min-width: 961px) {
      display: none;
    }
  }

  .selected-insurer {
    width: 100%;
    display: block;
    height: 120px;
    margin: 0 auto 16px;
    object-fit: contain;
  }

  .proceed-btn {
    @media only screen and (min-width: 961px) {
      display: none;
    }
    z-index: 1;
    width: 100%;
    background: $primary-color;
    color: $primary-text-color;
    .mat-button-wrapper {
      display: flex;
      align-items: center;
    }
    .title {
      font-size: 16px;
      font-weight: 500;
      line-height: 150%;
      text-align: left;
      flex: 1;
    }
    .value {
      text-align: right;
      font-size: 18px;
      font-weight: 700;
      line-height: 120%;
    }
    .desc {
      font-size: 10px;
      font-weight: 500;
      line-height: 120%;
      text-align: right;
    }
  }

  .expand-btn {
    @media only screen and (min-width: 961px) {
      display: none;
    }
    background: $primary-color;
    border: 2px solid #fff;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 100;
    color: #fff;
    top: -15px;
    box-shadow: 0px 0px 15px 0px #00000040 !important;
    height: 35px;
    width: 35px;
    mat-icon {
      // border: 1px solid #fff;
      width: 30px;
      height: 30px;
      border-radius: 100px;
      top: -6px;
      line-height: 31px;
      position: relative;
    }
  }

  .cover-label {
    display: block;
    width: fit-content;
  }
  .payment-options {
    @media only screen and (max-width: 960px) {
      display: none;
    }
  }
}

// comparison, topup-request
.summary-expand {
  box-shadow: none !important;
  border: 1px solid $secondary-color;
  border-radius: 8px !important;
  margin: 20px 0px;

  .mat-expansion-indicator {
    width: 28px;
    height: 28px;
    text-align: center;
    &::after {
      color: $secondary-color;
    }
  }

  .mat-expansion-panel-header {
    padding: 12px 20px !important;
    display: flex;
  }

  .mat-expansion-panel-body {
    padding: 0px 16px 16px;
  }

  .mat-expansion-panel-header-title {
    font-size: 12px;
    font-weight: 700;
  }
}

// comparison, topup-request
.cover-label {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  width: 100%;
  color: $tag-color;
  border: 1px solid $tag-color;
  padding: 8px 12px;
  border-radius: 5px;
}
// END SHARED STYLES

.mat-error {
  margin-top: 4px;
  display: none !important;
}

.text-right {
  text-align: right;
}

.mat-checkbox-disabled {
  opacity: 0.5;
}

.mat-flat-button.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12) !important;
  color: #ffffff !important;
}

.ngx-floating .mat-button.country-selector {
  min-width: fit-content !important;
  top: 8px !important;
  text-align: left;
  padding-left: 4px !important;
  width: 80px !important;

  > span {
    display: flex;
    align-items: center;
  }
}

input.country-search {
  outline: none;
}

// Swiper for landing page, must be placed global styles
.swiper-container {
  .swiper-pagination-bullet-active {
    width: 48px !important;
    height: 16px;
    border-radius: 16px;
    background-color: #ffffff !important;

    @media only screen and (max-width: 550px) {
      width: 21px !important;
      height: 7px;
    }
  }

  .swiper-pagination-bullet {
    width: 16px;
    height: 16px;
    background-color: rgb(82, 82, 82);

    @media only screen and (max-width: 550px) {
      width: 7px;
      height: 7px;
    }
  }

  .swiper-pagination-horizontal {
    bottom: 15px;

    @media only screen and (max-width: 550px) {
      bottom: 12px;
    }
  }
}

// Endorsement page
.endorsement-swiper {
  .swiper-slide {
    overflow: hidden;
    border-radius: 5px;
  }
}

//Tooltip
.tooltip-icon {
  position: absolute;
  margin-top: 16px;
  margin-left: 8px;
  font-size: 20px;
  width: 20px;
  height: 20px;
  transform: rotate(0.03deg);
  color: #7092bb;
}

.tooltip-popover {
  border: 1px solid $primary-color;
  margin-top: -24px;
  font-size: 12px;
  font-weight: 500;
  line-height: 150%;
  white-space: pre-line;
  span {
    display: block;
    padding: 16px;
  }
  .title {
    font-weight: 700;
  }
  .mat-menu-content {
    padding: 0px !important;
  }

  &.dashboard {
    max-width: 400px !important;
    width: 100%;
    @media (max-width: 600px) {
      max-width: 275px !important;
      width: 100%;
    }
  }
}

.tooltip-popover-d2c {
  font-size: 13px !important;
  background-color: #fff !important;
  border: 1px solid #0091ff;
  color: #000 !important;
  font-weight: 600 !important;
  white-space: pre-line;
  padding: 1px 12px;
  box-shadow: none;
}

::ng-deep .mat-tooltip {
  white-space: pre-line; // Allow line break
}

.mat-radio-button.mat-accent.mat-radio-checked {
  .mat-radio-inner-circle {
    background-color: $active-color;
  }
  .mat-radio-outer-circle {
    border-color: $active-color;
  }
}

.text-center {
  text-align: center;
}

.mat-option-text {
  font-weight: 800 !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: $primary-color !important;
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgb(169 169 169 / 12%);
}

.label-with-tooltip {
  font-weight: 500;
  display: block;
  margin-bottom: 8px;
  .mat-icon {
    margin-top: 0px;
    position: relative;
    top: 6px;
  }
}

.mat-select-search-clear {
  padding: 0px !important;
}

.mobile-details-btn-list {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 24px;
  @media only screen and (min-width: 961px) {
    display: none;
  }
  & > div {
    flex: 1;
    &:last-child {
      button {
        background: $primary-color;
        color: white;
      }
    }
  }
  button {
    width: 100%;
    padding: 4px 8px !important;
    background: #c1e4ff;
    font-weight: 700;
    img {
      height: 28px;
      image-rendering: -moz-crisp-edges; /* Firefox */
      image-rendering: -o-crisp-edges; /* Opera */
      image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
      image-rendering: crisp-edges;
      -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
    }
  }
}

.title-img {
  vertical-align: middle;
  top: -2px;
  position: relative;
  margin-right: 8px;
}

.edit-quote-dialog {
  .mat-dialog-container {
    padding: 32px !important;
  }
}

.coverage-desc {
  margin-bottom: 8px;
  font-weight: 600;
  line-height: 120%;
  margin-top: 6px;
  ul {
    margin: 0px;
    padding-left: 18px;
  }
}

.mat-dialog-content::-webkit-scrollbar {
  width: 8px;
}

.mat-dialog-content::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

.mat-dialog-content::-webkit-scrollbar-thumb {
  background: #334251;
}

.mat-select-panel::-webkit-scrollbar {
  width: 8px;
}

.mat-select-panel::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

.mat-select-panel::-webkit-scrollbar-thumb {
  background: #334251;
}

.additional-radio-btn {
  display: inline-block;
  &.right {
    width: 50%;
    padding-left: 8px;
  }

  &.full {
    display: flex;
    align-items: center;
    mat-label {
      width: 164px;
      top: 4px;
      position: relative;
    }
  }
  mat-label {
    color: black !important;
  }
  .mat-radio-label-content {
    font-weight: 800;
    color: $secondary-color;
    opacity: 1 !important;
    position: relative;
    top: 2px;
  }
  .mat-radio-outer-circle {
    border-color: $secondary-color;
  }
  .mat-radio-group {
    display: flex;
  }
  .mat-radio-checked .mat-radio-label-content {
    color: $primary-color !important;
  }
}

.f-w-500 {
  font-weight: 500;
}

.hidden-alert-snackbar {
  display: none !important;
}

.edit-car-variant-dialog {
  .mat-dialog-container {
    padding: 0px !important;
  }
  max-height: 750px;
}

//Button
// .customer-policy.mat-raised-button,
// .payment-page.mat-raised-button {
// 	box-shadow: 0px 3px 6px 1px #c1c5e46b !important;
// 	font-size: 14px;
// 	padding: 16px !important;
// 	min-height: fit-content !important;
// 	line-height: 18px !important;
// 	font-weight: 700;

// 	&.mat-primary {
// 		color: white;
// 	}

// 	&.secondary {
// 		background: #e0ecff;
// 		color: #0c67ff;
// 	}
// }

//Cover Label
// .comparison-form {
// 	background: #fafafa;
// 	padding: 50px 0px;
// 	@media only screen and (max-width: 960px) {
// 		padding: 24px 0px;
// 	}
// 	.sidefix-menu {
// 		right: 16px;
// 	}

// 	.mat-step-label {
// 		font-weight: 600;
// 		color: #d8d8d8 !important;

// 		@media only screen and (max-width: 960px) {
// 			font-size: 10px;
// 			line-height: 120%;
// 		}
// 		.mat-step-text-label {
// 			white-space: break-spaces;
// 			text-transform: capitalize;
// 		}
// 	}
// 	.mat-step-icon {
// 		background-color: #d8d8d8 !important;
// 		color: white !important;
// 		font-size: 25px;
// 		padding: 20px;
// 		.mat-icon {
// 			font-size: 25px;
// 			height: 25px;
// 			width: 25px;
// 			line-height: 25px;
// 		}
// 	}

// 	.mat-step-icon-state-edit {
// 		@media only screen and (max-width: 960px) {
// 			padding: 11px !important;
// 		}

// 		.mat-icon {
// 			@media only screen and (max-width: 960px) {
// 				font-size: 17px;
// 				height: 26px;
// 				width: 16px;
// 			}
// 		}
// 	}

// 	.mat-step-icon-state-done, .mat-step-icon-state-close {
// 		@media only screen and (max-width: 960px) {
// 			padding: 14px !important;
// 		}
// 	}

// 	.mat-step-icon-state-pay {
// 		@media only screen and (max-width: 960px) {
// 			padding: 14px !important;
// 		}
// 		.mat-icon {
// 			@media only screen and (max-width: 960px) {
// 				font-size: 19px;
// 				height: 26px;
// 				width: 19px;
// 			}
// 		}
// 	}

// 	.mat-step-label-active {
// 		font-weight: 600;
// 		color: $primary-color !important;
// 		text-transform: capitalize;
// 	}
// 	.mat-step-icon-selected,
// 	.mat-step-icon-state-done {
// 		background-color: $primary-color !important;
// 		color: white !important;
// 	}
// 	.mat-step-icon-state-edit {
// 		background-color: transparent !important;
// 		border: 3px solid $primary-color !important;
// 		color: $primary-color !important;
// 	}
// 	.mat-step-header {
// 		width: 25%;
// 		background: none !important;
// 		// padding: 30px 0px;
// 		&::before,
// 		&::after {
// 			width: calc(50% - 24px) !important;
// 			margin-top: 10px;
// 			border-top-width: 4px !important;

// 			// for stepper line in mobile view
// 			@media only screen and (max-width: 960px) {
// 				width: calc(50% - 13px) !important;
// 				margin-top: 6px;
// 			}
// 		}
// 		&:focus {
// 			background: none;
// 		}
// 	}

// 	.mat-step-header {
// 		&::before,
// 		&::after {
// 			border-color: $primary-color;
// 		}
// 	}

// 	.mat-step-header[aria-disabled="true"] {
// 		&::before,
// 		&::after {
// 			border-color: #d8d8d8 !important;
// 		}
// 	}

// 	.mat-stepper-horizontal,
// 	.mat-stepper-vertical {
// 		max-width: 1000px;
// 		margin: 0 auto;
// 		background-color: unset;
// 	}
// 	.mat-horizontal-content-container {
// 		@media only screen and (max-width: 960px) {
// 			padding: 16px;
// 		}
// 	}
// 	.mat-horizontal-stepper-header-container {
// 		// @media only screen and (max-width: 960px) {
// 		//   position: sticky;
// 		//   top: 70px;
// 		//   background: #eef4fc;
// 		//   z-index: 10;
// 		// }
// 	}
// 	.comparison-actions {
// 		display: flex;
// 		width: 100%;
// 		margin-top: 16px;
// 		@media only screen and (max-width: 960px) {
// 			display: none;
// 		}
// 		button {
// 			flex: 1;
// 		}
// 		.back-btn {
// 			background: none;
// 			border: 1px solid #ffd100;
// 			color: #ffd100;
// 			font-weight: 800;
// 			margin-right: 8px;
// 		}
// 		.proceed-btn {
// 			background: #ffd100;
// 			color: #000;
// 			font-weight: 700;
// 			margin-left: 8px;
// 			&.mat-button-disabled {
// 				background-color: rgba(0, 0, 0, 0.12) !important;
// 				color: #a6a6a6;
// 			}
// 		}
// 	}
// }

// .split-notice {
// 	font-size: 12px;
// 	background-color: #fff5c6;
// 	border-radius: 5px;
// 	padding: 10px 12px;
// 	text-align: right;
// 	font-weight: 500;
// 	@media only screen and (max-width: 960px) {
// 		display: none;
// 	}
// }

// .promo-input {
// 	@media only screen and (min-width: 961px) {
// 		// padding-bottom: 16px !important;
// 	}
// 	.mat-form-field {
// 		width: 100%;
// 	}
// 	.mat-form-field-wrapper {
// 		padding-bottom: 0px;
// 	}
// 	.mat-icon-button {
// 		top: 2px;
// 	}
// 	.mat-flat-button {
// 		background-color: #ffd100;
// 		color: #000;
// 		min-width: 120px;
// 		top: -4px;
// 		right: -10px;
// 		border-radius: 0px;
// 		padding: 17px 0px !important;
// 	}
// 	&.mat-form-field-appearance-outline .mat-form-field-outline {
// 		color: $secondary-color;
// 	}
// 	input {
// 		text-transform: uppercase;
// 		font-weight: 600 !important;
// 	}
// 	.mat-form-field-appearance-outline .mat-form-field-outline {
// 		color: #ffd100;
// 	}
// }

// .promo-error {
// 	font-size: 14px;
// 	line-height: 150%;
// 	padding-left: 12px;
// 	margin-top: 2px;
// 	font-weight: 500;
// 	color: #ff0022;
// }

// .promo-valid {
// 	font-size: 14px;
// 	line-height: 150%;
// 	padding-left: 12px;
// 	margin-top: 2px;
// 	font-weight: 500;
// 	color: $primary-color;
// }

// .expand-content {
// 	display: flex;
// 	margin-top: 16px;
// 	font-weight: 500;
// 	line-height: 150%;
// 	.mat-icon {
// 		margin-right: 8px;
// 	}
// 	.desc {
// 		font-size: 12px;
// 		font-weight: 500;
// 		line-height: 150%;
// 		color: $secondary-color;
// 	}
// }

// .multi-checkbox-section {
// 	margin-top: 50px;
// 	padding: 16px;
// 	background-color: $secondary-sub-color;
// 	.mat-checkbox-label {
// 		white-space: pre-wrap;
// 		color: black;
// 		line-height: 150%;
// 		font-weight: 500 !important;
// 	}
// 	.mat-checkbox-inner-container {
// 		margin: 0px 12px auto 0;
// 		height: 24px;
// 		width: 24px;
// 	}
// 	a {
// 		color: $secondary-color;
// 		text-decoration: none;
// 	}
// 	.mat-checkbox-checked.mat-accent .mat-checkbox-background {
// 		background: $active-color !important;
// 	}
// }

// .add-ons-container {
// 	display: flex;
// 	flex-wrap: wrap;
// 	.add-ons-cover {
// 		font-weight: bold;
// 		background-color: $secondary-color;
// 		border: 1px solid $secondary-color;
// 		border-radius: 100px;
// 		color: $secondary-text-color;
// 		width: fit-content;
// 		padding: 4px 16px;
// 		margin-right: 12px;
// 		margin-bottom: 12px;
// 	}
// }

// .edit-card {
// 	padding: 16px;
// 	border: 1px solid #73859c;
// 	border-radius: 5px;
// 	margin-bottom: 16px;
// 	section {
// 		margin-bottom: 10px;
// 	}
// 	.mat-checkbox-label {
// 		white-space: pre-wrap;
// 		color: black;
// 		line-height: 150%;
// 	}
// 	.title {
// 		font-size: 14px;
// 		font-weight: 700;
// 		color: black;
// 		line-height: 150%;
// 	}
// 	.mat-form-field {
// 		width: 100%;
// 	}
// 	.mat-form-field-wrapper {
// 		padding: 0px !important;
// 	}
// 	.b2c-radio {
// 	}
// 	.mat-slider-horizontal {
// 		width: 100%;
// 		margin-bottom: -10px;
// 	}
// }

// .dialog-title {
// 	color: $primary-color;
// 	font-weight: bold;
// 	font-size: 18px;
// 	margin-bottom: 16px;
// }

// Edit quotation slider //
// .slider-wrapper {
// 	margin-bottom: 16px;
// 	.mat-slider-track-fill {
// 		background: $active-color !important;
// 	}
// 	.mat-slider-thumb {
// 		background: white !important;
// 		border: 4px solid $active-color;
// 		transform: scale(0.7) !important;
// 	}
// }
// End edit quotation slider //

// .estimate-price {
// 	background: #fff3bd;
// 	padding: 10px 16px;
// 	border: 2px solid #fab700;
// 	border-radius: 5px;
// 	width: fit-content;
// }

// .border-card {
// 	box-shadow: none !important;
// 	margin-bottom: 60px;
// 	@media only screen and (max-width: 960px) {
// 		// display: none !important;
// 		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
// 	}
// 	& > div {
// 		display: flex;
// 		flex-wrap: wrap;
// 		margin-bottom: 16px;
// 		& > div {
// 			&:first-child {
// 				@media only screen and (max-width: 960px) {
// 					border-bottom: 1px solid #7092bb;
// 					border-right: none;
// 					margin-bottom: 16px;
// 					padding-bottom: 16px;
// 				}
// 				border-right: 1px solid #7092bb;
// 			}
// 			&:last-child {
// 				@media only screen and (min-width: 961px) {
// 					padding-left: 24px;
// 				}
// 			}
// 		}

// 		img {
// 			vertical-align: middle;
// 			top: -2px;
// 			position: relative;
// 			margin-right: 8px;
// 		}

// 		.divider {
// 			border: 1px solid black;
// 			height: 100%;
// 			width: 1px;
// 		}
// 	}
// }

// .footer {
// 	background-color: #334251;
// 	padding: 100px 16px;
// 	@media only screen and (max-width: 960px) {
// 		padding: 50px 24px;
// 	}
// 	.content-wrapper {
// 		max-width: 1200px;
// 		margin: 0 auto;
// 	}
// 	.footer-title {
// 		font-size: 24px;
// 		font-weight: 700;
// 		line-height: 150%;
// 		color: $primary-color;
// 		margin-bottom: 20px;
// 	}
// 	.footer-desc {
// 		font-size: 18px;
// 		font-weight: 300;
// 		line-height: 150%;
// 		color: #fefefe;
// 	}
// 	.footer-logo {
// 		margin-bottom: 24px;
// 		@media only screen and (max-width: 960px) {
// 			margin: 0 auto 50px;
// 		}
// 	}
// 	.copyright-section {
// 		font-size: 18px;
// 		font-weight: 300;
// 		line-height: 150%;
// 		color: #fefefe;
// 		text-align: center;
// 		max-width: 1200px;
// 		margin: 50px auto 0;
// 		@media only screen and (max-width: 960px) {
// 			font-size: 14px;
// 			text-align: left;
// 		}
// 	}
// 	a {
// 		font-size: 18px;
// 		font-weight: 300;
// 		line-height: 150%;
// 		color: #fefefe;
// 		text-decoration: none;
// 		display: block;
// 		margin-bottom: 20px;
// 	}
// 	.icon-text-wrapper {
// 		margin-top: 20px;
// 		.mat-icon {
// 			background: $primary-color;
// 			border-radius: 100px;
// 			padding: 5px;
// 			color: white;
// 			font-size: 20px;
// 			height: 30px;
// 			top: 4px;
// 			width: 30px;
// 			margin-right: 10px;
// 		}
// 	}
// 	.icon-text {
// 		font-size: 18px !important;
// 		font-weight: 300 !important;
// 		line-height: 34px;
// 		color: #fefefe;
// 	}
// }

// .hide-mobile {
// 	@media only screen and (max-width: 960px) {
// 		display: none !important;
// 	}
// }

// .menu-space {
// 	width: 140px;
// 	@media only screen and (min-width: 1460px) {
// 		width: 0px;
// 	}
// }

// .insurer-list {
// 	@media only screen and (max-width: 960px) {
// 		display: none;
// 	}
// 	&.cheapest {
// 		background-color: #fff5c6;
// 		.insurer-select {
// 			background: var(--insurer-right-cheapest) no-repeat left;
// 			background-size: cover;
// 		}
// 		.insurer-details img {
// 			filter: $img-mask-third;
// 		}
// 	}
// 	.banner {
// 		position: absolute;
// 		left: -10px;
// 		top: -16px;
// 		width: 123px;
// 		image-rendering: -moz-crisp-edges;
// 		image-rendering: -o-crisp-edges;
// 		image-rendering: -webkit-optimize-contrast;
// 		image-rendering: crisp-edges;
// 		-ms-interpolation-mode: nearest-neighbor;
// 		filter: $banner-filter !important;

// 		&.long {
// 			width: 160px;
// 		}
// 	}
// 	.banner-text {
// 		position: absolute;
// 		top: -10px;
// 		left: 10px;
// 		line-height: 1;
// 		font-weight: 600;
// 		font-size: 12px;
// 		color: #fff;
// 	}
// 	position: relative;
// 	background-color: #d8eeff;
// 	margin-top: 24px;
// 	box-shadow: 0 3px 6px 1px #c1c5e46b !important;

// 	.insurer-logo {
// 		background: url(assets/image/insurer-left.png) no-repeat left;
// 		background-size: cover;
// 		height: 170px;
// 		padding: 28px;
// 		img {
// 			width: 100%; //210px;
// 			// display: block;
// 			height: 100%;
// 		}
// 	}
// 	.insurer-details {
// 		flex: 1;
// 		padding: 16px;
// 		img {
// 			margin-right: 12px;
// 			object-fit: contain;
// 			width: 42px;
// 		}
// 		.label {
// 			font-size: 12px;
// 			font-weight: 400;
// 			line-height: 150%;
// 		}
// 		.value {
// 			font-size: 16px;
// 			font-weight: 700;
// 			line-height: 150%;
// 		}
// 		.benefit-btn {
// 			border-radius: 100px;
// 			color: $primary-color;
// 			border: 1px solid $primary-color;
// 		}
// 	}
// 	.insurer-content {
// 		display: flex;
// 		width: 100%;
// 		align-items: center;
// 	}

// 	.insurer-select {
// 		text-align: center;
// 		background: var(--insurer-right) no-repeat left;
// 		background-size: cover;
// 		height: 100%;
// 		width: 310px;
// 		padding: 16px 16px 16px 68px;
// 		.insurer-amount {
// 			font-size: 38px;
// 			line-height: 120%;
// 			font-weight: bold;
// 			color: #e33939;
// 			text-align: right;
// 			span {
// 				font-size: 24px;
// 				font-weight: 700;
// 				margin-right: 8px;
// 			}
// 		}
// 		.insurer-instalment {
// 			font-size: 12px;
// 			line-height: 120%;
// 			margin: 0px 0px 10px;
// 			b {
// 				font-size: 16px;
// 			}
// 		}
// 	}
// 	.buy-btn {
// 		border-radius: 100px;
// 		display: block;
// 		width: 100%;
// 		color: white;
// 		background: linear-gradient(180deg, #ff9850 0%, #ff4d00 99.91%);
// 	}
// }

// .comparison-graphic {
// 	display: block;
// 	@media (max-width: 960px) {
// 		display: none;
// 	}
// }

// .mobile-comparison-graphic {
// 	display: none;
// 	@media (max-width: 960px) {
// 		display: block;
// 		text-align: center;

// 		img {
// 			width: 100%;
// 			max-width: 400px;
// 			height: auto;
// 		}
// 	}
// }

// .mobile-insurer-list {
// 	background: $primary-color;
// 	border: 1px solid $primary-color;
// 	position: relative;
// 	border-radius: 5px;
// 	margin: 16px -16px 0px;
// 	@media only screen and (min-width: 961px) {
// 		display: none;
// 	}
// 	&.cheapest {
// 		background-color: $secondary-color;
// 		border: 1px solid $secondary-color;
// 		.insurer-logo {
// 			border-right: 1px solid $secondary-color;
// 			border-bottom: 1px solid $secondary-color;
// 		}
// 		.insurer-details {
// 			background-color: $secondary-sub-color;
// 		}
// 		.insurer-select {
// 			.insurer-amount {
// 				color: $secondary-text-color !important;
// 			}
// 			.insurer-instalment {
// 				color: $secondary-text-color !important;
// 			}
// 		}
// 		.insurer-value {
// 			.label {
// 				color: $secondary-text-color;
// 				font-size: 12px !important;
// 			}
// 			.value {
// 				color: $secondary-text-color;
// 				font-size: 12px !important;
// 			}
// 		}
// 	}
// 	.banner {
// 		position: absolute;
// 		left: -10px;
// 		top: -16px;
// 		width: 150px;
// 		filter: $banner-filter !important;

// 		&.no-filter {
// 			filter: none !important;
// 		}
// 	}
// 	.banner-text {
// 		position: absolute;
// 		top: -10px;
// 		left: 10px;
// 		line-height: 1;
// 		font-weight: 600;
// 		font-size: 12px;
// 		color: #fff;
// 	}

// 	.insurer-logo {
// 		width: 50%;
// 		background-color: white;
// 		border-right: 1px solid $primary-color;
// 		border-bottom: 1px solid $primary-color;
// 		border-radius: 0px 0px 38px 0px;
// 		text-align: center;
// 		padding: 5px;
// 		img {
// 			height: 110px;
// 			width: auto;
// 			max-width: 100%;
// 			object-fit: contain;
// 		}
// 	}

// 	.insurer-select {
// 		padding: 0px 8px;
// 		width: 50%;
// 		text-align: center;
// 		height: 100%;
// 		// padding: 16px 16px 16px 80px;
// 		.insurer-amount {
// 			margin-bottom: 8px;
// 			font-size: 25px;
// 			line-height: 150%;
// 			font-weight: bold;
// 			color: $secondary-text-color;
// 			span {
// 				font-size: 20px;
// 				font-weight: 700;
// 				margin-right: 8px;
// 			}
// 		}
// 		.insurer-instalment {
// 			font-size: 12px;
// 			line-height: 150%;
// 			margin: 0px 0px 10px;
// 			color: $secondary-text-color !important;
// 			b {
// 				font-size: 16px;
// 			}
// 		}
// 	}

// 	.label {
// 		font-size: 8px;
// 		font-weight: 400;
// 		line-height: 150%;
// 	}
// 	.value {
// 		font-size: 10px;
// 		font-weight: 700;
// 		line-height: 150%;
// 	}

// 	.insurer-value {
// 		.label {
// 			color: $secondary-text-color;
// 			font-size: 12px !important;
// 		}
// 		.value {
// 			color: $secondary-text-color;
// 			font-size: 12px !important;
// 		}
// 	}

// 	.insurer-details {
// 		background-color: $primary-sub-color;
// 		padding: 10px 16px;
// 		img {
// 			margin-right: 6px;
// 			object-fit: contain;
// 			width: 34px;
// 		}
// 	}

// 	.buy-btn {
// 		border-radius: 100px;
// 		display: block;
// 		width: 100%;
// 		color: white;
// 		background: linear-gradient(180deg, #ff9850 0%, #ff4d00 99.91%);
// 	}

// 	.benefit-btn {
// 		padding: 0px 10px;
// 		margin-left: 6px;
// 		font-size: 10px;
// 		border-radius: 100px;
// 		color: $primary-color;
// 		border: 1px solid $primary-color;
// 	}
// 	.insurer-value {
// 		padding: 10px;
// 		display: flex;
// 		align-items: center;
// 	}
// }

// .mobile-insurer-list-2 {
// 	position: relative;
// 	display: none;

// 	@media (max-width: 961px) {
// 		display: block;
// 	}

// 	box-shadow: 0px 4px 4px rgba(230, 232, 255, 0.25),
// 		0px 4px 20px 3px rgba(181, 183, 223, 0.25);
// 	border-radius: 10px;
// 	background-color: #fff7d3;
// 	margin: 18px 0px;
// 	.insurer-logo {
// 		height: 100%;
// 		vertical-align: middle;
// 		text-align: center;
// 		img {
// 			width: 100px;
// 			padding-top: 10px;
// 			vertical-align: middle;
// 		}
// 	}

// 	.info-section {
// 		display: flex;
// 		justify-content: space-between;
// 		align-items: center;
// 		padding: 20px;
// 	}

// 	.insurer-details {
// 		flex: 1;
// 		padding: 20px;
// 		img {
// 			margin-right: 12px;
// 			object-fit: contain;
// 			width: 42px;
// 		}
// 		.label {
// 			font-size: 10px;
// 			font-weight: 400;
// 			line-height: 150%;
// 		}
// 		.value {
// 			font-size: 14px;
// 			font-weight: 700;
// 			line-height: 150%;
// 		}
// 		.benefit-btn {
// 			border-radius: 100px;
// 			color: $primary-color;
// 			border: 1px solid $primary-color;
// 		}
// 	}

// 	.price-section {
// 		&.cheapest {
// 			background-color: #0091ff;

// 			.price {
// 				color: #fff !important;
// 			}
// 			.split-price {
// 				color: #fff !important;
// 			}
// 		}
// 		background-color: #ffd100;
// 		padding: 20px;

// 		.price {
// 			font-size: 28px;
// 			font-weight: 700;
// 			color: #000;
// 		}

// 		.split-price {
// 			margin-top: 18px;
// 			color: #000;
// 			font-size: 12px;
// 		}

// 		.buy-btn {
// 			// border-radius: 100px;
// 			display: block;
// 			color: white;
// 			background: linear-gradient(148.73deg, #ff9750 11.9%, #ff4c00 80.17%);
// 			border-radius: 19px;
// 			width: 90%;
// 			margin: 0px 15px;
// 		}
// 	}

// 	.view-benefits-btn {
// 		color: #0091ff;
// 		border: 1px solid #0091ff;
// 		border-radius: 20px;
// 		font-size: 10px;
// 		padding: 0px 10px !important;
// 	}

// 	&.cheapest {
// 		background: #d8eeff;
// 	}

// 	.banner {
// 		position: absolute;
// 		left: -10px;
// 		top: -16px;
// 		width: 150px;
// 	}
// }

// // affiliate insurer list
// .partner-insurer-list {
// 	&.cheapest {
// 		background-color: $primary-sub-color !important;
// 		.insurer-select {
// 			background: var(--insurer-right-cheapest) no-repeat left !important;
// 			background-size: cover !important;
// 			.insurer-amount {
// 				color: $primary-text-color !important;
// 			}
// 			.insurer-instalment {
// 				color: $primary-text-color !important;
// 			}
// 		}
// 	}

// 	background-color: $secondary-sub-color !important;

// 	.insurer-logo {
// 		background: var(--insurer-left) no-repeat left;
// 		background-size: cover;
// 	}
// 	.insurer-details {
// 		.benefit-btn {
// 			color: $primary-color !important;
// 			border: 1px solid $primary-color !important;
// 		}
// 	}
// 	.insurer-select {
// 		background: var(--insurer-right) no-repeat left !important;
// 		background-size: cover !important;
// 		.insurer-amount {
// 			color: $secondary-text-color !important;
// 		}
// 		.insurer-instalment {
// 			color: $secondary-text-color !important;
// 		}
// 	}
// 	.buy-btn {
// 		color: $primary-button-text-color;
// 		background: $primary-button;
// 	}
// }

// .other-insurers {
// 	background-color: #fff7d3;
// 	border: 1px solid #e2a600;
// 	border-radius: 8px;
// 	margin: 50px 0px;
// 	padding: 25px 30px;

// 	.title {
// 		font-weight: 700;
// 		font-size: 18px;
// 	}

// 	.logo-container {
// 		text-align: center;
// 	}

// 	.logo {
// 		width: 95px;
// 		@media (max-width: 600px) {
// 			width: 70px;
// 		}
// 	}
// }

// .email-wrapper {
// 	white-space: pre-wrap;
// 	.icon-text {
// 		display: flex;
// 	}
// }

// .cursor-pointer {
// 	cursor: pointer;
// }

// .whatsapp-btn {
// 	position: fixed;
// 	z-index: 10;
// 	bottom: 56px;
// 	right: 32px;
// 	display: block;
// 	cursor: pointer;
// 	@media only screen and (max-width: 960px) {
// 		display: none;
// 	}
// }

// .accept-payment-graphic {
// 	display: flex;
// 	flex-wrap: wrap;
// 	align-items: center;
// 	padding: 16px 40px 0px;
// 	img {
// 		max-width: 100%;
// 		margin-left: 4px;
// 		image-rendering: auto;
// 		image-rendering: crisp-edges;

// 		/* Safari seems to support, but seems deprecated and does the same thing as the others. */
// 		image-rendering: -webkit-optimize-contrast;
// 	}
// }

// .header-language {
// 	width: 45px;
// 	height: 36px;
// 	color: black;
// 	border-radius: 100px !important;
// 	// background-color: #d8eeff;
// 	border: none !important;
// 	// padding: 4px 14px !important;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	img {
// 		width: 20px;
// 		height: 20px;
// 		margin-left: 4px;
// 	}
// }

// .header-menu {
// 	border-radius: 5px !important;
// 	margin-left: 16px;
// 	background-color: $primary-color;
// }

// .edit-summary-btn {
// 	border-radius: 100px !important;
// 	border: 1px solid $tag-color !important;
// 	padding: 2px 8px !important;
// 	line-height: 150% !important;
// 	color: $tag-color;
// 	margin-left: 16px !important;
// }

// .remaining-amount-container {
// 	background: #ffeded;
// 	border-radius: 11px;
// 	padding: 10px 15px 3px;
// 	margin-bottom: 10px;

// 	align-items: center !important;

// 	.left {
// 		width: 50%;
// 		padding-right: 0px !important;
// 	}
// 	.right {
// 		width: 50%;
// 		padding-left: 8px !important;
// 	}
// }

// .remaining-amount {
// 	color: #e33939;
// 	font-weight: 700;
// 	font-size: 21px;
// 	white-space: nowrap;
// }

// .slider-input {
// 	max-width: 200px;
// 	display: block !important;
// 	margin: 0 auto;
// 	.mat-form-field-prefix {
// 		span {
// 			top: -3px;
// 			position: relative;
// 		}
// 	}
// }

// .ps-mobile-next-btn {
// 	width: 100%;
// 	.container {
// 		display: flex;
// 		width: 100%;

// 		.total-txt {
// 			flex: 1;
// 			font-size: 16px;
// 			font-weight: 500;
// 			color: #000;
// 		}

// 		.price {
// 			text-align: right;
// 			flex: 1;
// 			.txt {
// 				font-size: 16px;
// 				font-weight: 700;
// 				color: #000;
// 				padding-bottom: 10px;
// 			}
// 			.desc {
// 				font-size: 12px;
// 				color: #000;
// 				white-space: nowrap;
// 			}
// 		}
// 	}
// 	.mat-flat-button {
// 		background-color: #ffd100;
// 		color: #000;
// 		font-weight: 700;
// 		width: 100%;
// 		margin-top: 10px;
// 	}
// }
// .recommended-add-ons {
// 	background-color: #d8eeff;
// 	padding: 15px 20px;

// 	margin: 25px 0px;
// }

// .payment-buttons {
// 	display: flex;
// 	flex-wrap: wrap;
// 	@media only screen and (max-width: 960px) {
// 		margin-bottom: 30vh !important;
// 	}
// 	& > div {
// 		width: 50%;
// 	}
// 	.desc {
// 		color: #646464;
// 		font-size: 14px;
// 		font-weight: 500;
// 		line-height: 150%;
// 	}

// 	.title {
// 		color: #646464;
// 		font-weight: 800;
// 		font-size: 20px;
// 		line-height: 150%;
// 	}
// 	button {
// 		display: flex;
// 		justify-content: center;
// 		border: 1px solid #bbbbbb;
// 		width: 100%;
// 		background-color: white;
// 		text-align: center;
// 		padding: 16px;
// 		white-space: break-spaces;
// 		height: 100%;
// 		align-items: center;
// 		&.selected {
// 			border: 1px solid $primary-color;
// 			background: #d8eeff;
// 			.desc,
// 			.title {
// 				color: $primary-color;
// 			}
// 			mat-icon {
// 				display: block;
// 				color: $primary-color;
// 				position: absolute;
// 				right: -8px;
// 				top: -8px;
// 				background: white;
// 				border-radius: 100px;
// 			}
// 		}
// 	}
// }

// .upload-container {
// 	background: #fff3bd;
// 	padding: 24px 36px;
// 	.title {
// 		font-size: 18px;
// 		line-height: 150%;
// 		font-weight: 800;
// 		text-align: center;
// 	}

// 	.upload-card {
// 		margin-top: 24px;
// 		border: 1px solid #fab700;
// 		box-shadow: none !important;
// 		img {
// 			width: 60px;
// 			margin-right: 16px;
// 			margin-top: -8px;
// 		}
// 		.title {
// 			text-align: left;
// 			margin-bottom: 4px;
// 		}
// 		.desc {
// 			font-size: 14px;
// 			font-weight: 500;
// 			line-height: 150%;
// 		}
// 		.mat-stroked-button {
// 			margin-top: 16px;
// 			border: 1px solid $secondary-color;
// 			font-weight: 800;
// 			line-height: 150%;
// 			padding: 10px 16px;
// 			.mat-icon {
// 				color: $primary-color;
// 				margin-right: 8px;
// 			}
// 		}

// 		.uploaded-list {
// 			margin-top: 16px;
// 			display: flex;
// 			align-items: center;
// 			.mat-slider-track-wrapper {
// 				height: 6px;
// 			}
// 			.mat-slider-track-background {
// 				height: 6px;
// 			}
// 			.mat-slider-track-fill {
// 				height: 6px;
// 			}
// 			.mat-slider-thumb {
// 				display: none;
// 			}

// 			.upload-progress {
// 				margin-top: -10px;
// 				margin-left: -8px;
// 				display: flex;
// 				align-items: center;
// 				margin-bottom: -10px;
// 				padding-right: 16px;
// 				.mat-slider {
// 					flex: 1;
// 				}
// 				span {
// 					margin-left: 16px;
// 					font-size: 14px;
// 					font-weight: 800;
// 					line-height: 150%;
// 					color: $primary-color;
// 				}
// 			}
// 		}
// 		.stop-upload-btn {
// 			color: red;
// 		}
// 		.uploaded-icon {
// 			top: 1px;
// 			position: relative;
// 			color: green;
// 			margin-right: 6px;
// 		}
// 	}
// }

//Icon Content
// .icon-content {
// 	padding: 150px 56px 50px;
// 	&.second-step {
// 		@media only screen and (min-width: 961px) {
// 			margin-top: 290px;
// 		}
// 	}
// 	@media only screen and (max-width: 960px) {
// 		padding: 50px 24px;
// 	}
// 	max-width: 1200px;
// 	margin: 0 auto;
// 	.content-box {
// 		width: 33%;
// 		@media only screen and (max-width: 960px) {
// 			width: 100%;
// 		}
// 		img {
// 			margin-bottom: 24px;
// 		}
// 		margin-bottom: 50px;
// 		@media only screen and (max-width: 960px) {
// 			margin-bottom: 24px;
// 		}

// 		.title-f {
// 			margin-bottom: 10px;
// 		}
// 	}
// }

//Graphic Content
// .graphic-bg-mobile {
// 	width: 100%;
// 	margin-bottom: -30%;
// 	@media only screen and (min-width: 961px) {
// 		display: none;
// 	}
// }

// .graphic-content {
// 	background: url(assets/image/graphic-bg-1.png) no-repeat top center;
// 	width: 100%;
// 	background-size: cover;
// 	padding: 100px 56px;
// 	@media only screen and (min-width: 1700px) {
// 		background: url(assets/image/graphic-bg-1.png) no-repeat top left;
// 		background-size: contain;
// 	}

// 	@media only screen and (max-width: 960px) {
// 		padding: 0px 24px 50px;
// 		background: none;
// 	}
// 	& > div {
// 		max-width: 1200px;
// 		margin: 0 auto;
// 	}
// 	.content-box {
// 		max-width: 700px;
// 		@media only screen and (max-width: 960px) {
// 			width: 100%;
// 			max-width: unset;
// 		}
// 		.lgTitle-f {
// 			margin-bottom: 20px;
// 		}
// 		.desc-f {
// 			margin-bottom: 60px;
// 		}
// 	}
// 	.icon-box-wrapper {
// 		display: flex;
// 		flex-wrap: wrap;
// 		& > div {
// 			width: 50%;
// 			@media only screen and (max-width: 960px) {
// 				margin-top: 12px;
// 				width: 100%;
// 			}
// 		}
// 	}

// 	.graphic-icon-box {
// 		background: white;
// 		padding: 32px;
// 		border-radius: 20px;
// 		box-shadow: 0px 0px 17px 9px #0000001a;
// 		height: 100%;
// 		position: relative;
// 		.count {
// 			background: #fab700;
// 			border: 3px solid #ffffff;
// 			width: 50px;
// 			height: 50px;
// 			border-radius: 100px;
// 			font-size: 30px;
// 			color: white;
// 			font-weight: bold;
// 			display: flex;
// 			justify-content: center;
// 			align-items: center;
// 			position: absolute;
// 			right: -12px;
// 			top: -12px;
// 		}
// 		.mdTitle-f {
// 			margin-bottom: 10px;
// 		}
// 		img {
// 			// filter: $img-mask-primary;
// 		}
// 	}
// }

//Image Content
// .image-content {
// 	background: url(assets/image/image-content-bg.png) no-repeat top center;
// 	width: 100%;
// 	background-size: cover;
// 	padding: 100px 56px;
// 	@media only screen and (max-width: 960px) {
// 		padding: 50px 24px;
// 	}
// 	button {
// 		display: block;
// 		margin: 50px auto 0px;
// 		color: white;
// 		border: 2px solid white !important;
// 		font-size: 18px;
// 		min-width: 300px;
// 		line-height: 150%;
// 		padding: 10px 0px;
// 		font-weight: 700;
// 	}
// 	.lgTitle-f {
// 		color: white;
// 		margin-bottom: 50px;
// 		text-align: center;
// 	}
// .swiper {
//   max-width: 1200px;
//   margin: 0 auto;
//   &.single {
//     @media only screen and (min-width: 961px) {
//       display: none;
//     }
//   }
//   &.multiple {
//     @media only screen and (max-width: 960px) {
//       display: none;
//     }
//   }
//   img {
//     width: 100%;
//     margin-bottom: 10px;
//   }
//   .swiper-pagination {
//     bottom: 0px;
//   }
//   .swiper-pagination-bullet-active {
//     background: white !important;
//   }
//   .swiper-pagination-bullet {
//     height: 14px;
//     width: 14px;
//   }
// }
// }

// //affiliates Landing Page Form
// .form-container-bg {
// 	background: radial-gradient(
// 		95.1% 95.1% at -5.03% -5.78%,
// 		#00a0ff 0%,
// 		#0091ff 56.05%,
// 		#004cc0 100%
// 	);
// 	margin-top: -74px;
// 	margin-bottom: 10px;
// 	min-height: 90vh;
// 	// height: 100%;

// 	& > div {
// 		background: url("./assets/image/page-form-bg-2.png") no-repeat;
// 		// -webkit-background-size: contain;
// 		// -moz-background-size: contain;
// 		// -o-background-size: contain;
// 		// background-size: contain;
// 		background-position: 90% 50%;
// 		padding-top: 150px;

// 		@media (min-width: 1900px) {
// 			background-position: 80% 50%;
// 		}
// 		@media (max-width: 1200px) {
// 			background-attachment: fixed !important;
// 		}
// 		@media only screen and (max-width: 960px) {
// 			padding-top: 90px;
// 		}

// 		& > div {
// 			max-width: 1350px;
// 			margin: 0 auto;
// 			display: flex;
// 			flex-wrap: wrap;
// 			@media only screen and (max-width: 960px) {
// 				flex-direction: column-reverse !important;
// 			}
// 		}
// 	}

// 	.forms {
// 		padding: 0px 16px;
// 		width: 450px;
// 		@media only screen and (max-width: 960px) {
// 			padding: 0px;
// 			width: 100%;
// 		}
// 		.title {
// 			padding: 30px 40px 0px;
// 			font-size: 25px;
// 			line-height: 35px;
// 			font-weight: 800;
// 			margin-bottom: 8px;
// 			@media only screen and (max-width: 960px) {
// 				padding: 30px 24px 0px;
// 			}
// 		}
// 		.mat-card {
// 			padding: 0px;
// 			width: 450px;
// 			position: absolute;
// 			padding-bottom: 24px;
// 			height: fit-content;
// 			@media only screen and (max-width: 960px) {
// 				width: 100%;
// 				padding-bottom: 32px;
// 				position: relative;
// 			}
// 		}

// 		.mat-flat-button {
// 			width: 100%;
// 			background: #ffd100;
// 			color: #000;
// 			font-weight: 800;
// 			margin-top: 40px;
// 			&.mat-button-disabled {
// 				color: #a6a6a6;
// 			}
// 		}

// 		.mat-form-field {
// 			width: 100%;
// 		}
// 		.mat-form-field-wrapper {
// 			padding-bottom: 12px;
// 		}
// 		.mat-horizontal-stepper-header-container {
// 			// margin-bottom: 16px;
// 			padding: 0px 40px;
// 			@media only screen and (max-width: 960px) {
// 				padding: 0px 24px;
// 			}
// 		}

// 		.mat-horizontal-stepper-header {
// 			width: 80px;
// 			height: 35px;
// 			font-size: 14px;
// 			font-weight: 800;
// 			text-align: center;
// 			border-radius: 10px;
// 			margin-right: -12px;
// 			background: url("./assets/image/step-bg-inactive.png") no-repeat;
// 			background-size: contain;
// 			&:first-child {
// 				z-index: 1;
// 			}
// 			span {
// 				color: #0091ff;
// 			}
// 		}
// 		// mat-checkbox
// 		.mat-checkbox-checked.mat-accent .mat-checkbox-background {
// 			// background: #fff !important;
// 			// border: 1px solid $active-color;
// 			background-color: $active-color !important;
// 		}

// 		mat-step-header:first-of-type {
// 			background: var(--step-1) no-repeat;
// 			&[aria-selected="true"] {
// 				background: var(--step-1-active) no-repeat;
// 			}
// 		}

// 		mat-step-header:last-of-type {
// 			background: var(--step-2) no-repeat;
// 			&[aria-selected="true"] {
// 				background: var(--step-2-active) no-repeat;
// 			}
// 			.mat-step-label {
// 				margin-left: 0px;
// 			}
// 		}

// 		.mat-step-header[aria-selected="true"] {
// 			opacity: 1;
// 			span {
// 				color: #fff;
// 			}
// 		}

// 		.mat-step-label {
// 			font-size: 14px;
// 			font-weight: 800;
// 			width: 100%;
// 			margin-left: -8px;
// 		}
// 		.mat-step-icon {
// 			display: none;
// 		}

// 		.mat-stepper-horizontal-line {
// 			display: none;
// 		}
// 		.mat-horizontal-content-container {
// 			padding: 0px 40px;
// 			@media only screen and (max-width: 960px) {
// 				padding: 0px 34px 0px 24px;
// 			}
// 		}
// 	}

// 	.copy-writing {
// 		color: white;
// 		text-align: center;
// 		padding: 0px 24px 0px 52px;
// 		@media only screen and (max-width: 960px) {
// 			width: 100%;
// 			min-height: 150px;
// 			height: 100%;
// 			padding: 0px !important;
// 		}
// 		.title {
// 			font-size: 28px;
// 			line-height: 150%;
// 			font-weight: 700;
// 			margin-bottom: 10px;
// 			@media only screen and (max-width: 960px) {
// 				font-size: 24px;
// 			}
// 		}
// 		.desc {
// 			font-size: 16px;
// 			line-height: 150%;
// 			width: 80%;
// 			margin: 0 auto;
// 			@media only screen and (max-width: 960px) {
// 				font-size: 14px;
// 			}
// 		}
// 		img {
// 			display: block;
// 			width: 100%;
// 			margin-top: 55px;
// 			max-height: 385px;
// 			object-fit: contain;
// 			@media only screen and (max-width: 960px) {
// 				width: 100%;
// 				max-height: 250px;
// 				margin: 5px auto 0;
// 				// object-fit: contain;
// 			}
// 		}
// 	}
// }

// // affiliate dashboard
// .partner-form-container {
// 	background: var(--partner-bg) no-repeat !important;
// 	background-size: cover !important;
// 	max-width: 100%;
// 	height: 100%;
// 	min-height: 100vh;
// 	margin-bottom: 0px !important;

// 	@media (max-width: 960px) {
// 		background: none !important;
// 	}

// 	.copy-writing {
// 		@media only screen and (max-width: 960px) {
// 			background: var(--partner-bg) no-repeat !important;
// 			background-size: cover !important;
// 			object-fit: cover;
// 			margin: -15px 0px 0px !important;
// 			min-height: 30vh !important;
// 			height: 100% !important;
// 			max-width: 100%;
// 		}
// 	}

// 	& > div {
// 		background: none !important;
// 		padding-bottom: 42px !important;
// 		@media (max-width: 960px) {
// 			padding-bottom: 0px !important;
// 		}
// 	}

// 	.mat-card {
// 		position: relative !important;
// 	}

// 	// radio btn
// 	.b2c-radio {
// 		.mat-radio-outer-circle {
// 			border-color: #7092bb !important;
// 		}
// 		.mat-radio-checked {
// 			.mat-radio-outer-circle {
// 				border-color: $active-color !important;
// 			}
// 			.mat-radio-inner-circle {
// 				background-color: $active-color !important;
// 			}
// 		}
// 	}
// 	// mat-checkbox
// 	.mat-checkbox-checked.mat-accent .mat-checkbox-background {
// 		background: #fff !important;
// 	}

// 	// mat-checkbox check color
// 	// mat-checkbox.dashboardCheckbox .mat-checkbox-checkmark-path {
// 	//   stroke: $active-color !important;
// 	// }

// 	// mat-checkbox label color (on active)
// 	.mat-checkbox-checked .mat-checkbox-label {
// 		color: $active-text-color;
// 	}

// 	// step one and step 2 in dashboard
// 	mat-step-header:first-of-type {
// 		background: var(--step-1) no-repeat !important;
// 		&[aria-selected="true"] {
// 			background: var(--step-1-active) no-repeat !important;
// 		}
// 	}

// 	mat-step-header:last-of-type {
// 		background: var(--step-2) no-repeat !important;
// 		&[aria-selected="true"] {
// 			background: var(--step-2-active) no-repeat !important;
// 		}
// 	}

// 	.mat-horizontal-stepper-header {
// 		span {
// 			color: $primary-color !important;
// 		}
// 	}

// 	.mat-step-header[aria-selected="true"] {
// 		span {
// 			color: #fff !important;
// 		}
// 	}
// }

// .payment-page {
// 	text-align: center;
// 	max-width: 600px;
// 	margin: 50px auto;
// 	mat-icon {
// 		height: 64px;
// 		width: 64px;
// 		font-size: 64px;
// 		line-height: 64px;
// 		color: #2acf92;
// 		margin-bottom: 10px;
// 	}
// 	&.fail {
// 		mat-icon {
// 			color: #e91e63;
// 		}
// 	}
// 	.title-sec {
// 		margin-bottom: 50px;
// 	}
// 	.full-btn {
// 		margin-top: 10px;
// 	}
// }

// .country-selector-flag {
// 	top: -2px;
// 	position: relative;
// }

// // Customer policy
// .customer-policy {
// 	background-color: #eef4fc;

// 	mat-panel-title {
// 		padding-left: 10px;
// 	}

// 	.main-title {
// 		color: #0091ff;
// 		font-weight: 800;
// 		font-size: 25px;
// 		text-align: center;
// 	}

// 	.secondary-title {
// 		font-weight: 800;
// 		font-size: 25px;
// 		text-align: center;
// 	}

// 	.expandable-card {
// 		box-shadow: none !important;
// 		border-radius: 8px;
// 		margin: 20px 0px;
// 		padding: 0px;

// 		.content {
// 			padding: 0px 15px 15px;
// 		}

// 		.input-field {
// 			width: 100%;
// 			padding: 0px 35px 0px 10px;
// 			margin: 0px;
// 		}

// 		.gender-btn {
// 			padding: 10px 40px !important;
// 		}

// 		.slider {
// 			border: none;
// 			width: 80%;
// 			margin-top: 50px !important;
// 		}
// 	}
// }

// Refund request
// .refund-request {
// 	.vehicle-reg-container {
// 		display: flex;
// 		justify-content: center !important;
// 		align-items: center !important;
// 		min-height: 45px;

// 		background: #134a8c;
// 		border: 1px solid #7092bb;
// 		border-radius: 8px;
// 		padding: 10px 25px;
// 		margin-top: 30px;
// 	}
// }

// upload-documents
// .upload-documents {
// 	mat-card {
// 		border: 1px solid #7092bb;
// 		border-radius: 8px;
// 		box-shadow: none !important;
// 		margin-top: 15px;

// 		img {
// 			width: 60px;
// 			margin-right: 16px;
// 			margin-top: 0px;
// 		}
// 		button {
// 			.mat-icon {
// 				color: $primary-color;
// 				margin-right: 8px;
// 			}
// 		}
// 	}
// }

// .affiliate-ftr {
// 	background: $footer-bg-color;
// 	height: 100%;
// 	max-width: 1400px;
// 	margin: 0 auto;
// 	div {
// 		height: 100%;
// 		display: flex;
// 		justify-content: flex-start;
// 		align-items: center;
// 		max-width: 850px;

// 		color: $footer-text-color;
// 		& > div {
// 			// flex: 1;
// 			margin: 25px;
// 		}
// 	}
// 	.logo-container {
// 		margin: 10px 0px;
// 	}
// 	.logo {
// 		max-width: 200px;
// 	}
// 	.copyright {
// 		display: none;
// 		flex-direction: column;
// 		align-items: flex-start;
// 	}
// 	.copyright-text {
// 		margin-top: 10px;
// 		margin-bottom: 0px;
// 		text-align: left;
// 	}
// 	.copyright-text::before {
// 		content: $footer-copyright;
// 	}
// 	.ps {
// 		padding: 0px;
// 		.powered-by {
// 			flex-direction: column;
// 			justify-content: center;
// 			align-items: flex-start;
// 			&.affiliate {
// 				text-align: center;
// 				@media (max-width: 960px) {
// 					padding-bottom: 0px;
// 					margin-bottom: 0px;
// 				}
// 				img {
// 					width: 200px;
// 					margin-top: 10px;
// 					// height: 100%;
// 				}
// 			}
// 		}
// 		hr {
// 			height: 100px;
// 			display: none;
// 		}
// 		img {
// 			width: 120px;
// 			height: 100%;
// 		}
// 	}
// 	.phone,
// 	.whatsapp,
// 	.email {
// 		white-space: nowrap;
// 		margin: 2px 0px;
// 	}
// 	.address-content::before {
// 		content: $footer-custom-address;
// 	}
// 	.phone-content::before {
// 		content: $footer-custom-phone;
// 	}
// 	.whatsapp-content::before {
// 		content: $footer-custom-whatsapp;
// 	}
// 	.email-content::before {
// 		content: $footer-custom-email;
// 	}
// 	.address {
// 		padding-top: 20px;
// 		max-width: 300px;
// 		width: 100%;
// 	}
// 	.contact {
// 		display: flex;
// 		flex-direction: column;
// 		justify-content: center;
// 		align-items: flex-start;
// 		padding-top: 20px;
// 	}
// 	@media (max-width: 960px) {
// 		display: flex;
// 		justify-content: flex-start;
// 		align-items: flex-start;
// 		width: 100%;
// 		text-align: left;
// 		div {
// 			align-items: flex-start;
// 		}

// 		.copyright-text {
// 			padding-bottom: 20px;
// 		}

// 		.logo-container {
// 			width: 100%;
// 			margin: 0px;
// 			padding: 10px;
// 		}

// 		div {
// 			flex-direction: column;
// 		}

// 		.copyright {
// 			align-items: center;
// 			margin-bottom: 0px !important;
// 			border-bottom: 1px solid #d3d3d3;
// 			width: 90%;
// 			padding: 0px 20px !important;
// 		}

// 		.ps {
// 			text-align: left;
// 			margin: 0px !important;
// 			padding: 0px !important;
// 			padding-left: 5px !important;
// 			.powered-by {
// 				img {
// 					min-width: 100px;
// 					height: 100%;
// 				}
// 			}
// 			hr {
// 				display: none;
// 			}
// 		}

// 		.address {
// 			max-width: 325px;
// 			padding: 0px !important;
// 			padding-left: 30px !important;
// 			margin: 0px !important;
// 			text-align: left;
// 		}

// 		.contact {
// 			padding-top: 0px !important;
// 			margin: 15px 0px !important;
// 			padding-left: 30px !important;
// 		}
// 	}
// }

// .regNoPaddingBtm p {
// 	@media (max-width: 960px) {
// 		padding-bottom: 115px;
// 	}
// }

// .regNo {
// 	background-color: #122545;
// 	color: #fff;

// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// 	text-align: center;
// 	padding: 20px 20px 5px !important;

// 	width: 100%;
// }

// .regNo-content::before {
// 	content: $footer-registration-no;
// }
