//Form

.form-field {
	&.with-sub-radio {
		.mat-radio-button {
			width: 100%;
			margin-bottom: 16px;
			margin-left: 24px;
			font-weight: 500;
			position: relative;
			top: -4px;
			&:first-of-type {
				margin-bottom: 10px;
			}
		}
	}
	section {
		width: 100%;
		.label-desc {
			margin-bottom: 4px;
		}
		.mat-checkbox {
			width: 100%;
			margin-bottom: 16px;
			&.left {
				width: 50%;
				padding-right: 8px;
			}
			&.right {
				width: 50%;
				padding-left: 8px;
			}
		}

		.mat-checkbox-label {
			font-size: 16px;
			font-weight: 600;
			line-height: 20px;
			position: relative;
			top: 1px;
		}
	}
	.mat-form-field {
		width: 100%;
		input,
		textarea,
		.mat-select-value-text {
			font-weight: 600;
		}
		.mat-form-field-label-wrapper {
			font-weight: 600;
		}
		&.left {
			width: 50%;
			box-sizing: border-box;
			padding-right: 8px;
		}
		&.right {
			width: 50%;
			box-sizing: border-box;
			padding-left: 8px;
		}

		&.mobile-full {
			@media (max-width: $mobile-max) {
				width: 100%;
				padding: 0px;
			}
		}
		.mat-form-field-suffix .mat-icon {
			color: #94a3b8;
		}
	}

	.preview-img-container {
		position: relative;
		margin: 0 auto 20px;
		img {
			width: 130px;
			height: 130px;
			border-radius: 50%;
			object-fit: contain;
			border: 1px solid #cdd5f3;
			background: #eef0f8;
		}
		button {
			position: absolute;
			bottom: 0px;
			right: 0px;
			.mat-icon {
				color: white;
			}
		}
	}
	.mat-card-content {
		display: flex;
		flex-wrap: wrap;
	}
}

.mat-radio-group {
	width: 100%;
	flex-wrap: wrap;
	display: flex;
	.mat-radio-button {
		font-weight: 600;
		margin-bottom: 12px;

		width: 100%;
		@media (min-width: $mobile-min) {
			flex: 1;
		}
	}
}

.mat-form-field-invalid {
	// margin-bottom: 12px !important;
}

.ngx-mat-tel-input-container {
	font-weight: 600;
}

.mat-datepicker-content .mat-calendar {
	height: fit-content !important;
}

.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button,
.mat-datepicker-toggle .mat-icon-button {
	height: 24px;
	width: 24px;
	mat-icon {
		font-size: 24px !important;
	}
	svg {
		font-size: 24px;
		color: #94a3b8;
	}
}

.mat-button.country-selector {
	padding: 0px !important;
}

.mat-select-search-custom-header-content {
	font-weight: 600 !important;
	color: #ff0022;
}

::-webkit-input-placeholder {
	/* WebKit browsers */
	text-transform: none !important;
}
:-moz-placeholder {
	/* Mozilla Firefox 4 to 18 */
	text-transform: none !important;
}
::-moz-placeholder {
	/* Mozilla Firefox 19+ */
	text-transform: none !important;
}
:-ms-input-placeholder {
	/* Internet Explorer 10+ */
	text-transform: none !important;
}
::placeholder {
	/* Recent browsers */
	text-transform: none !important;
}

//Form

//Custom
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
	background: $active-color !important;
}

.mat-form-field-appearance-outline {
	.mat-form-field-infix {
		padding: 0.8em 0 0.9em !important;
		font-weight: 600;
	}

	.mat-select-arrow {
		margin-top: 4px !important;
		color: #7092bb !important;
	}
	.mat-form-field-outline {
		color: #7092bb !important;
	}

	&.mat-form-field-disabled {
		input {
			color: #7092bb;
		}
		.mat-form-field-outline {
			color: #7092bb !important;
		}
		.mat-select-value {
			color: #7092bb;
		}
		.mat-icon-button,
		.mat-select-arrow-wrapper {
			display: none !important;
		}
	}
}

.mat-input-element,
.mat-select-value-text {
	font-weight: 800 !important;
}

mat-label {
	font-weight: 500;
	color: #7092bb !important;
}

.mat-form-field-invalid mat-label {
	// color: #f44336 !important;
}

.mat-form-field-invalid .mat-input-element {
	caret-color: #7092bb !important;
}

.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid
	.mat-form-field-outline-thick {
	color: #7092bb;
}

section {
	margin-bottom: 16px;

	.mat-checkbox-label {
		font-size: 14px !important;
		font-weight: 700 !important;
		color: #7092bb;
	}

	.mat-checkbox-frame {
		border-color: #7092bb;
	}
}

.mat-datepicker-toggle-default-icon {
	height: 24px !important;
	width: 24px !important;
	color: #7092bb !important;
}

.textarea {
	height: 150px;
	&.message {
		border: none;
		border-radius: 5px;
		background-color: #fff;
		font-weight: 400;
		width: 92%;
		padding: 20px;
		overflow: hidden;
		&::placeholder {
			color: #7092bb;
			font-weight: 400;
		}
	}
}

// Input

// Input with only border bottom
.line-input {
	border: none;
	border-bottom: 1px solid #000;
	&:focus {
		outline: none;
	}
}

//Custom
.mat-form-field-subscript-wrapper {
	position: unset !important;
	margin-top: unset !important;
	margin-bottom: 8px;
}

.car-mileage {
  .mat-form-field-appearance-outline .mat-form-field-outline-end {
    border-radius: 0;
  }
}
