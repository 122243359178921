// Base Element
$partner-secondary-color: default;
$third-color: #ffd100;
$muted-color: var(--muted-color);
$red: #ff045f;
$green: #00d394;
$orange: #fac300;
$light-grey: #eef4fc;
$font-family: "Montserrat";
$img-mask-primary: "brightness(0) saturate(100%) invert(39%) sepia(86%) saturate(2579%) hue-rotate(187deg) brightness(102%) contrast(106%)";
$img-mask-third: "brightness(0) saturate(100%) invert(86%) sepia(17%) saturate(3798%) hue-rotate(356deg) brightness(98%) contrast(109%)";
// // End Base Element

$primary-color: var(--primary-color);
$primary-text-color: var(--primary-text-color);
$primary-sub-color: var(--primary-sub-color);
$primary-sub-text: var(--primary-sub-text);

$secondary-color: var(--secondary-color);
$secondary-text-color: var(--secondary-text-color);
$secondary-sub-color: var(--secondary-sub-color);
$secondary-sub-text: var(--secondary-sub-text);

$third-color: var(--third-color);
$third-text: var(--third-text);

$color-accent-red: #E30A12;
$color-green: #0A9830;
$color-light-blue: #EBF6FF;
$color-light-green: #24A1481A;

// Others: Radio Buttons, label (pill, tag), etc
$active-color: var(
  --active-color
); // Occured when clicked or selected or ticked or scaled
$active-text-color: var(--active-text-color);
$tag-color: var(--tag-color); // For labels, pills, etc
// End Others

// Insurer card
$primary-button: var(--primary-button);
$primary-button-text-color: var(--primary-button-text-color);
// End insurer card

// Footer
$footer-bg-color: var(--footer-bg-color);
$footer-text-color: var(--footer-text-color);
$footer-copyright: var(--footer-copyright);
$footer-custom-address: var(--footer-custom-address);
$footer-custom-phone: var(--footer-custom-phone);
$footer-custom-whatsapp: var(--footer-custom-whatsapp);
$footer-custom-email: var(--footer-custom-email);
$footer-registration-no: var(--footer-registration-no);
// End Footer

// Icon Filter
$icon-filter: var(--icon-filter);
$icon-filter-cheapest: var(--icon-filter-cheapest);
$banner-filter: var(--banner-filter);
// End Icon Filter

.color-primary {
  color: $primary-color !important;
}

.color-secondary {
  color: $third-color !important;
}

.color-white {
  color: #fff !important;
}

.color-green {
  color: #25d366;
}

.color-free {
  color: #24a148;
  font-weight: 700;
}

.color-blue {
  color: red;
}

.color-red {
  color: red;
}

.color-dark-blue {
  color: #324f73;
}

.bg-light-grey {
  background-color: $light-grey !important;
}

.bg-black {
  background: #000;
}

.bg-white {
  background: #fff;
}
