//Font

.bold {
	font-weight: bold !important;
}

// Title Font Size
.title-f {
	font-size: 24px;
	font-weight: bold;
	line-height: 150%;
	
	@media only screen and (max-width: 960px) {
		font-size: 18px;
	}
}

.mdTitle-f {
	font-size: 18px !important;
	font-weight: bold !important;
	line-height: 150%;
}

.lgTitle-f {
	font-weight: bold;
	font-size: 36px;
	line-height: 150%;
}
// End Title Font Size

// Desc Font Size
.desc-f {
	font-size: 18px;
	line-height: 150%;
	font-weight: 500;
}

.xsDesc-f {
	font-weight: 400;
	font-size: 11px;
}

.smDesc-f {
	font-weight: 400;
	font-size: 12px;
}

.mdDesc-f {
	font-weight: 500;
	font-size: 14px;
	line-height: 150%;
}
// End Desc Font Size

.label-desc {
	font-size: 14px;
	line-height: 150%;
	font-weight: 500;
}

.label-value {
	font-size: 14px;
	font-weight: 700;
	line-height: 150%;
}

.icon-text-wrapper {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	.icon-text:first-of-type {
		margin-right: 10px;
	}
}

.icon-text {
	&.contact {
		background: #dff3ff;
		border: 1px solid white;
		color: #44535c;
		font-size: 12px !important;
		padding: 4px 8px;
		border-radius: 5px;
		box-shadow: 0px 0px 6px #0b599e4f inset;
		cursor: pointer;
		width: fit-content;
	}
	.mat-icon {
		color: #94a3b8;
		height: 14px;
		width: 14px;
		font-size: 14px;
		top: 2px;
		margin-right: 6px;
		position: relative;
	}
	font-size: 14px !important;
	font-weight: 500 !important;
	color: #94a3b8;
}
//Font
