$xs-space: 8px;
$sm-space: 16px;
$md-space: 24px;
$lg-space: 32px;

//Margin Top
.mt-xs {
	margin-top: $xs-space !important;
}

.mt-sm {
	margin-top: $sm-space !important;
}

.mt-md {
	margin-top: $md-space !important;
}

.mt-lg {
	margin-top: $lg-space !important;
}

//Margin Bottom
.mb-xs {
	margin-bottom: $xs-space !important;
}

.mb-sm {
	margin-bottom: $sm-space !important;
}

.mb-md {
	margin-bottom: $md-space !important;
}

.mb-lg {
	margin-bottom: $lg-space !important;
}

//Margin Left
.ml-xs {
	margin-left: $xs-space !important;
}

.ml-sm {
	margin-left: $sm-space !important;
}

.ml-md {
	margin-left: $md-space !important;
}

.ml-lg {
	margin-left: $lg-space !important;
}

//Margin Right
.mr-xs {
	margin-right: $xs-space !important;
}

.mr-sm {
	margin-right: $sm-space !important;
}

.mr-md {
	margin-right: $md-space !important;
}

.mr-lg {
	margin-right: $lg-space !important;
}

//Margin Horizontal
.mh-xs {
	margin-left: $xs-space !important;
	margin-right: $xs-space !important;
}

.mh-sm {
	margin-left: $sm-space !important;
	margin-right: $sm-space !important;
}

.mh-md {
	margin-left: $md-space !important;
	margin-right: $md-space !important;
}

.mh-lg {
	margin-left: $lg-space !important;
	margin-right: $lg-space !important;
}

//Margin Vertical
.mv-xs {
	margin-top: $xs-space !important;
	margin-bottom: $xs-space !important;
}

.mv-sm {
	margin-top: $sm-space !important;
	margin-bottom: $sm-space !important;
}

.mv-md {
	margin-top: $md-space !important;
	margin-bottom: $md-space !important;
}

.mv-lg {
	margin-top: $lg-space !important;
	margin-bottom: $lg-space !important;
}

//Margin
.m-xs {
	margin: $xs-space !important;
}

.m-sm {
	margin: $sm-space !important;
}

.m-md {
	margin: $md-space !important;
}

.m-lg {
	margin: $lg-space !important;
}

//Padding Top
.pt-xs {
	padding-top: $xs-space !important;
}

.pt-sm {
	padding-top: $sm-space !important;
}

.pt-md {
	padding-top: $md-space !important;
}

.pt-lg {
	padding-top: $lg-space !important;
}

//Padding Bottom
.pb-xs {
	padding-bottom: $xs-space !important;
}

.pb-sm {
	padding-bottom: $sm-space !important;
}

.pb-md {
	padding-bottom: $md-space !important;
}

.pb-lg {
	padding-bottom: $lg-space !important;
}

//Padding Left
.pl-xs {
	padding-left: $xs-space !important;
}

.pl-sm {
	padding-left: $sm-space !important;
}

.pl-md {
	padding-left: $md-space !important;
}

.pl-lg {
	padding-left: $lg-space !important;
}

//Padding Right
.pr-xs {
	padding-right: $xs-space !important;
}

.pr-sm {
	padding-right: $sm-space !important;
}

.pr-md {
	padding-right: $md-space !important;
}

.pr-lg {
	padding-right: $lg-space !important;
}

//Padding Horizontal
.ph-xs {
	padding-left: $xs-space !important;
	padding-right: $xs-space !important;
}

.ph-sm {
	padding-left: $sm-space !important;
	padding-right: $sm-space !important;
}

.ph-md {
	padding-left: $md-space !important;
	padding-right: $md-space !important;
}

.ph-lg {
	padding-left: $lg-space !important;
	padding-right: $lg-space !important;
}

//Padding Vertical
.pv-xs {
	padding-top: $xs-space !important;
	padding-bottom: $xs-space !important;
}

.pv-sm {
	padding-top: $sm-space !important;
	padding-bottom: $sm-space !important;
}

.pv-md {
	padding: $md-space !important;
}

.pv-lg {
	padding: $lg-space !important;
}

//Padding
.p-xs {
	padding: $xs-space !important;
}

.p-sm {
	padding: $sm-space !important;
}

.p-md {
	padding: $md-space !important;
}

.p-lg {
	padding: $lg-space !important;
}
