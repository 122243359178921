//Header
// .header {
	// padding: 0px !important;
	// position: sticky;
	// top: 0;
	// background: none !important;
	// z-index: 100;
	// height: 74px !important;
	// transition: all 0.2s;
	// .page-logo,
	// .affiliate-logo {
	// 	display: none;
	// 	image-rendering: auto;
	// 	image-rendering: crisp-edges;

	// 	/* Safari seems to support, but seems deprecated and does the same thing as the others. */
	// 	image-rendering: -webkit-optimize-contrast;
	// }

	// // PS Desktop logo
	// .desktop-page-logo {
	// 	display: block;
	// 	width: 100%;
	// 	@media (max-width: 960px) {
	// 		display: none;
	// 	}
	// }

	// @media only screen and (max-width: $mobile-max) {
	// 	height: unset;
	// }
	// &.not-top {
	// 	background-color: white !important;
	// 	box-shadow: 0 3px 6px 1px #c1c5e46b !important;
	// 	.white-page-logo {
	// 		display: none;
	// 		image-rendering: auto;
	// 		image-rendering: crisp-edges;

	// 		/* Safari seems to support, but seems deprecated and does the same thing as the others. */
	// 		image-rendering: -webkit-optimize-contrast;
	// 	}
	// 	.page-logo {
	// 		display: none;
	// 	}
	// 	@media (max-width: 960px) {
	// 		display: block;
	// 	}
	// 	.affiliate-logo {
	// 		display: block;
	// 	}
	// 	.mat-button {
	// 		color: $primary-color;
	// 	}
	// }
	// .mat-button {
	// 	margin-left: 16px;
	// 	color: #ffffff;
	// 	padding: 10px 16px !important;
	// 	font-weight: 600;
	// }

	// .menu-container {
	// 	max-width: 1300px;
	// 	margin: 0 auto;

	// 	a {
	// 		width: 17%;
	// 	}
	// 	@media only screen and (max-width: 960px) {
	// 		background: #ffffff;
	// 		padding: 12px 16px !important;
	// 		.white-page-logo {
	// 			display: none !important;
	// 		}
	// 		.page-logo,
	// 		.affiliate-logo {
	// 			display: block !important;
	// 			max-width: 90px;
	// 		}
	// 		.mat-icon {
	// 			color: white;
	// 		}
	// 		.desktop-actions {
	// 			display: none;
	// 		}
	// 	}

	// 	.mobile-actions {
	// 		display: flex;
	// 		align-items: center;
	// 	}
	// 	@media only screen and (min-width: 961px) {
	// 		.mobile-actions {
	// 			display: none;
	// 		}
	// 	}
	// 	padding: 0px 25px;
	// 	flex: 1;
	// 	display: flex;
	// 	align-items: center;
	// 	height: 100%;
	// 	.page-title {
	// 		margin-left: 10px;
	// 		font-size: 20px;
	// 		font-weight: 800;
	// 		@media only screen and (max-width: $mobile-max) {
	// 			color: white;
	// 			position: absolute;
	// 			width: fit-content;
	// 			margin: 0 auto;
	// 			left: 0;
	// 			right: 0;
	// 			text-align: center;
	// 		}
	// 	}
	// }
// }

// .menu-container-affiliate {
// 	max-width: 1350px;
// 	margin: 0 auto;
// 	background: #ffffff;
// 	padding: 12px 16px !important;
// 	.white-page-logo {
// 		display: none !important;
// 	}
// 	.page-logo,
// 	.affiliate-logo {
// 		display: block !important;
// 		height: 35px;
// 		@media (max-width: 700px) {
// 			height: auto;
// 			max-width: 175px;
// 		}
// 	}
// 	.mat-icon {
// 		color: white;
// 	}
// 	.desktop-actions {
// 		display: none;
// 	}

// 	.mobile-actions {
// 		display: flex;
// 		align-items: center;
// 	}
// 	@media only screen and (min-width: 961px) {
// 		.mobile-actions {
// 			display: none;
// 		}
// 	}
// 	padding: 0px 16px;
// 	flex: 1;
// 	display: flex;
// 	align-items: center;
// 	height: 100%;
// 	.page-title {
// 		margin-left: 10px;
// 		font-size: 20px;
// 		font-weight: 800;
// 		@media only screen and (max-width: $mobile-max) {
// 			color: white;
// 			position: absolute;
// 			width: fit-content;
// 			margin: 0 auto;
// 			left: 0;
// 			right: 0;
// 			text-align: center;
// 		}
// 	}
// }

//Header

//Content Container
.content-container {
	max-width: 1200px;
	margin: 0 auto;
	padding: 16px;

	&.small {
		max-width: 700px;
	}
	&.xx-small {
		max-width: 500px;
	}

	&.full-height {
		height: 100vh;
	}
}
//Content Container

.page-container {
	position: relative;
	flex-grow: 1;
	// overflow-y: hidden;
	// overflow-x: auto;
	// background: #fafafa;
}

.full-width {
	width: 100%;
}

//Content Wrapper
.content-wrapper {
	display: flex !important;
	flex-wrap: wrap;
	width: 100%;
	margin-top: 10px;
	& > div {
		width: 100%;
		margin-bottom: 10px;
	}

	.left {
		width: 50%;
		padding-right: 8px;
	}
	.right {
		width: 50%;
		padding-left: 8px;
	}
	.cover-img {
		width: 100%;
		max-height: 200px;
		object-fit: contain;
		border: 1px solid #dbe0e6;
		margin-bottom: 16px;
		border-radius: 5px;
	}
	.profile-img {
		width: 100%;
		height: 130px;
		width: 130px;
		border-radius: 50%;
		object-fit: cover;
		border: 1px solid #dbe0e6;
		margin-bottom: 16 px;
	}
}

//Content Wrapper

.mat-card {
	box-shadow: none !important;
	padding: 24px;
	// border: 1px solid #73859c;
	box-shadow: 0 3px 6px 1px #c1c5e46b !important;
}

.flex-center {
	display: flex;
	justify-content: center;
	align-items: center;

	&.column {
		flex-direction: column;
	}
}
